<template>
    <div class="seo">
        <div class="title">
            <h2>赚密令</h2>
            <h4 @click="toHelp">帮助文档</h4>
        </div>
        <div @click="create(left)" class="btn" :class="{ invalid: left <= 0 }">
            创建密令词
        </div>

        <el-tabs @tab-click="handleClick" class="tab">
            <el-tab-pane
                v-for="(item, index) in seoList"
                :label="item.label"
                :name="item.name"
                :key="index"
            >
                <div v-if="item.list.length === 0" class="empty">
                    <img
                        src="https://img.alicdn.com/imgextra/i4/O1CN011tAkcX1PYGFUK7kGS_!!6000000001852-2-tps-374-448.png"
                    />
                    <div style="margin-top:10px;">暂无更多</div>
                </div>
                <el-table
                    v-else
                    :data="item.list"
                    style="width: 100%"
                    align="center"
                >
                    <el-table-column
                        align="center"
                        label="赚密令词"
                        width="220px"
                    >
                        <template slot-scope="scope">
                            <div style="width:100%">
                                <div>{{ scope.row.seoWord }}</div>
                                <div
                                    v-if="scope.row.status == 2"
                                    class="reason"
                                >
                                    未通过原因：赚密令词违规，请更换
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        label="活动链接"
                        width="320"
                    >
                        <template slot-scope="scope">
                            <a class="link" :href="scope.row.seoUrl">{{
                                scope.row.seoUrl
                            }}</a>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        :label="
                            index == 0 || index == 2 ? '申请时间' : '有效期截止'
                        "
                    >
                        <template slot-scope="scope">
                            <div v-if="index == 0 || index == 2">
                                {{ scope.row.startTime | formatTime }}
                            </div>
                            <div v-else>
                                {{ scope.row.endTime | formatTime }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" align="center" width="80">
                        <template slot-scope="scope">
                            <div :class="getClass(scope.row.status)">
                                {{ scope.row.status | getStatus }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <div
                                @click="process(scope.row, left)"
                                style="color: red; cursor: pointer;"
                            >
                                <template>
                                    {{ scope.row.status | getBtnTxt }}
                                </template>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>

        <el-dialog
            :title="isUpdate ? '修改密令词' : '创建密令词'"
            :visible.sync="showDialog"
            width="30%"
            :before-close="handleClose"
        >
            <div class="tip" v-if="!isUpdate">
                <div class="tip1">
                    <img
                        src="https://img.alicdn.com/imgextra/i4/O1CN011dKd6V1L1340xrahC_!!6000000001238-2-tps-108-108.png"
                    />
                    最多可创建<span style="font-weight:bold;padding: 0 2px;"
                        >2</span
                    >条赚密令，当前还可创建<span
                        style="font-weight: bold;padding: 0 2px"
                        >&nbsp;{{ left }}&nbsp;</span
                    >条
                </div>
                <div class="doc" @click="toHelp">
                    帮助文档
                </div>
            </div>
            <el-form
                :rules="rules"
                :model="{ ...info, dateRange }"
                ref="seoForm"
            >
                <el-form-item
                    label="推广链接: "
                    label-width="120px"
                    prop="seoUrl"
                >
                    <el-input
                        v-model="info.seoUrl"
                        autocomplete="off"
                        placeholder="请输入在APP搜索密令词打开的活动链接"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="密令词: "
                    label-width="120px"
                    prop="seoWord"
                >
                    <el-input
                        v-model="info.seoWord"
                        autocomplete="off"
                        placeholder="请输入5-10个字"
                    >
                        <span
                            slot="suffix"
                            style="padding-right: 10px; cursor: pointer;"
                            @click="showRuleModal"
                            ><i
                                class="el-input__icon el-icon-warning-outline"
                            ></i
                            >填写规则</span
                        >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="有效期: "
                    label-width="120px"
                    prop="dateRange"
                >
                    <el-date-picker
                        v-model="dateRange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="['00:00:00', '23:59:59']"
                        :picker-options="pickerOptions"
                    >
                    </el-date-picker>
                    <span class="warning">最长不超过60天</span>
                </el-form-item>
                <el-form-item label="推广APP: " label-width="120px">
                    <el-radio v-model="info.promotionApp" :label="1"
                        >手猫</el-radio
                    >
                    <el-radio v-model="info.promotionApp" :label="2"
                        >考拉</el-radio
                    >
                </el-form-item>
                <el-form-item label="推广渠道: " label-width="120px">
                    <el-radio v-model="info.promotionChannel" :label="1"
                        >其他</el-radio
                    >
                    <el-radio v-model="info.promotionChannel" :label="2"
                        >微博</el-radio
                    >
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submit">提交审核</el-button>
            </span>
        </el-dialog>

        <div class="wrapper">
            <el-pagination
                v-show="
                    (active == 2 && unPassTotal > 20) ||
                        (active == 3 && invalidTotal > 20)
                "
                background
                layout="prev, pager, next"
                :page-size="20"
                @current-change="handleCurrentChange"
                :total="active == 2 ? unPassTotal : invalidTotal"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { throws } from "assert";
import { mapState } from "vuex";

const initInfo = {
    id: "",
    seoUrl: "",
    seoWord: "",
    promotionApp: 2,
    promotionChannel: 1,
    startTime: Date.now(),
    endTime: 0,
    status: 0
};

const getArray = (n, start = 0) =>
    Array.from(new Array(n), (_, k) => k + start);

const pick = (obj, keys) =>
    keys.reduce((sum, key) => ((sum[key] = obj[key]), sum), {});

function afterDays(addCount, timeStamp = new Date().getTime()) {
    const aa = new Date(timeStamp);
    aa.setDate(aa.getDate() + addCount);

    const y = aa.getFullYear();
    const m =
        aa.getMonth() + 1 < 10 ? `0${aa.getMonth() + 1}` : aa.getMonth() + 1;
    const d = aa.getDate() < 10 ? `0${aa.getDate()}` : aa.getDate();

    return new Date(`${y}/${m}/${d}`);
}

function getToday() {
    const d = new Date();
    d.setHours(0, 0, 0, 0);

    return d;
}

export default {
    data() {
        const now = Date.now();
        let minTime = now - 864e5;
        let maxTime = now + 864e5 * 58;

        const checkUrl = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("请输入密令推广链接"));
            }

            if (
                this.info.promotionApp === 1 &&
                !/^https?:\/\/(\w+\.)+tmall\.com/.test(value)
            ) {
                return callback(
                    new Error("请输入合法天猫域名, 如 https://m.tmall.com")
                );
            }

            if (
                this.info.promotionApp === 2 &&
                !/^https?:\/\/(\w+)\.kaola\.com/.test(value)
            ) {
                return callback(
                    new Error("请输入合法考拉域名, 如 https://m.kaola.com")
                );
            }

            callback();
        };

        const checkTimePicker = (rule, value, callback) => {
            if (!value || value.length < 2) {
                return callback(new Error("请选择时间"));
            }

            if (value[1].getTime() < Date.now()) {
                return callback(new Error("结束时间已经早于当前时间，请修改"));
            }

            callback();
        };

        return {
            isUpdate: false,
            showDialog: false,
            active: "0",
            unPassTotal: 0,
            invalidTotal: 0,
            total: 0,
            info: { ...initInfo },
            seoList: [
                {
                    list: [],
                    name: "0",
                    label: "待审核",
                    pageIndex: 1,
                    hasMore: true,
                    count: 0
                },
                {
                    list: [],
                    name: "1",
                    label: "生效中",
                    pageIndex: 1,
                    hasMore: true,
                    count: 0
                },
                {
                    list: [],
                    name: "2",
                    label: "不通过",
                    pageIndex: 1,
                    hasMore: true,
                    count: 0
                },
                {
                    list: [],
                    name: "3",
                    label: "已过期",
                    pageIndex: 1,
                    hasMore: true,
                    count: 0
                }
            ],
            pageIndex: 1,
            pageSize: 100,
            dateRange: [],
            rules: {
                seoWord: [
                    {
                        required: true,
                        message: "请输入密令词",
                        trigger: "blur"
                    },
                    {
                        min: 5,
                        max: 10,
                        message: "长度在 5 到 10 个字符",
                        trigger: "blur"
                    }
                ],
                seoUrl: [
                    {
                        required: true,
                        message: "请输入推广链接",
                        trigger: "blur"
                    },
                    { validator: checkUrl, trigger: "blur" }
                ],
                dateRange: [
                    {
                        required: true,
                        message: "请输入开始或结束时间",
                        trigger: "blur"
                    },
                    { validator: checkTimePicker, trigger: "blur" }
                ]
            },
            pickerOptions: {
                onPick({ minDate, maxDate }) {
                    if (!maxDate) {
                        const range = 59 * 864e5;
                        minTime = minDate.getTime(); // 最小时间
                        maxTime = minDate.getTime() + range; // 最大时间
                    } else {
                        minTime = maxTime = null;
                    }
                },
                disabledDate(time) {
                    minTime = Math.max(minTime, Date.now() - 864e5);
                    if (minTime && maxTime) {
                        return (
                            time.getTime() < minTime || time.getTime() > maxTime
                        );
                    }

                    return time.getTime() < minTime;
                }
            }
        };
    },

    created() {
        getArray(4).forEach(status => this.getListByStatus(status, 1));
        getArray(2, 2).forEach(status => this.getTotal(status));
    },

    computed: {
        ...mapState(["zhuankeType"]),
        left() {
            const { list: l1 } = this.seoList[0];
            const { list: l2 } = this.seoList[1];

            return Math.max(2 - l1.length - l2.length, 0);
        }
    },

    methods: {
        handleCurrentChange(index) {
            if (this.active == 2) {
                this.seoList[2].pageIndex = index;
                this.getListByStatus(2, index);
            } else {
                this.seoList[3].pageIndex = index;
                this.getListByStatus(3, index);
            }
        },
        getTotal(status) {
            this.$request({
                url: "/api/seo/count",
                params: { status }
            }).then(data => {
                if (status == 2) {
                    this.unPassTotal = data;
                } else {
                    this.invalidTotal = data;
                }
            });
        },
        showRuleModal() {
            this.$alert(
                `
                同一个赚客id最多可创建2条赚密令，赚密令下线或者失效后可新增创建。赚密令词需符合广告法，禁止使用品类词及虚假宣传、绝对性描述、黄赌毒等违禁词，更多详情见帮助文档。
            `,
                "帮助文档",
                {
                    confirmButtonText: "我知道了",
                    showClose: true
                }
            );
        },
        handleClick({ index }) {
            if (this.active !== index) {
                this.active = index;
                this.getListByStatus(Number(index), 1);
            }
        },
        handleClose(done) {
            this.$refs.seoForm.clearValidate();
            this.info = initInfo;
            this.dateRange = [];
            this.isUpdate = false;
            done();
        },
        cancel() {
            this.handleClose(() => (this.showDialog = false));
        },
        submit() {
            this.$refs.seoForm.validate(valid => {
                if (!valid) {
                    return false;
                }
                // doSub
                const data = pick(this.info, [
                    "seoUrl",
                    "seoWord",
                    "promotionChannel",
                    "promotionApp"
                ]);

                const [s, e] = this.dateRange;

                Object.assign(data, {
                    startTime: Math.max(s.getTime(), Date.now()),
                    endTime: e.getTime()
                });

                console.log("[submit data]", data);

                const url = this.isUpdate
                    ? "/api/seo/update"
                    : "/api/seo/create";

                this.$request({ url, method: "post", data, toast: false })
                    .then(() => {
                        this.$message(`${this.isUpdate ? "修改" : "创建"}成功`);

                        setTimeout(() => {
                            window.location.reload();
                        }, 500);
                    })
                    .catch(e => {
                        console.error("request error", e);
                        let message = "更新失败，请稍后重试";

                        if (e && e.data) {
                            message = e.data;
                        } else if (e && e.desc) {
                            message = e.desc;
                        }

                        this.$message.error(message);
                    });
            });
        },
        async getListByStatus(status, pageIndex) {
            const item = this.seoList[status];
            if (item.ajaxLock || !item.hasMore) {
                return;
            }

            item.ajaxLock = true;

            try {
                const data =
                    (await this.$request({
                        url: "/api/seo/list",
                        toast: false,
                        loading: false,
                        params: {
                            status,
                            pageIndex,
                            pageSize: 20
                        }
                    })) || [];

                item.ajaxLock = false;
                item.hasMore = data && data.length > 0;
                this.total = data.length;

                const { list } = this.seoList[status];

                Object.assign(this.seoList[status], {
                    list: [...data],
                    pageIndex: pageIndex + 1
                });
            } catch (error) {
                item.hasMore = false;
                console.error("get list error", error);
                this.$message.error("获取密令列表失败，请稍后重试");
            }
        },
        getClass(status) {
            if (status == 1) {
                return { valid: true };
            }

            if (status == 2) {
                return { nopass: true };
            }

            if (status === 0) {
                return { pending: true };
            }

            return { outdate: true };
        },
        toHelp() {
            window.open(
                "https://www.yuque.com/docs/share/bcf1c39b-6d66-4297-9923-fddb229523fd"
            );
        },
        create(left) {
            if (left <= 0) return;

            // doCreate
            this.showDialog = true;
        },

        async process(row, left) {
            console.log(1111, row);
            const { status, id } = row;
            if (status === 1) {
                this.$confirm(
                    "确认要下架该赚密令吗?</br>删除后无法撤销，密令将失效",
                    "",
                    {
                        confirmButtonText: "确认下架",
                        cancelButtonText: "再想想",
                        dangerouslyUseHTMLString: true
                    }
                )
                    .then(() => {
                        this.$request({
                            method: "post",
                            url: "/api/seo/delete",
                            data: { id },
                            toast: false
                        }).catch(e => {
                            console.error("delete error", e);
                            this.$message.error("删除失败");
                        });
                    })
                    .catch(() => {});
            }

            // 修改
            if (status === 2) {
                if (left <= 0) {
                    return this.$message.error(
                        "您的密令条数已满2条，无法创建新密令"
                    );
                }

                this.isUpdate = true;
                this.info = await this.$request({
                    url: "/api/seo/item",
                    toast: false,
                    loading: false,
                    params: { id }
                });

                const { startTime, endTime } = this.info;

                this.dateRange = [new Date(startTime), new Date(endTime)];
                this.showDialog = true;
            }

            // 重新申请
            if (status === 3) {
                if (left <= 0) {
                    return this.$message.error("已满2条赚密令");
                }

                this.isUpdate = false;
                const res = await this.$request({
                    url: "/api/seo/item",
                    toast: false,
                    loading: false,
                    params: { id }
                });

                delete res.id;

                this.info = res;
                const { startTime, endTime } = res;
                this.dateRange = [new Date(startTime), new Date(endTime)];
                this.showDialog = true;
            }
        }
    },

    components: {},

    filters: {
        formatDate(time) {
            const a = new Date(time);

            const format = s => (`${s}`.length < 2 ? `0${s}` : s);

            const y = format(a.getFullYear());
            const m = format(a.getMonth() + 1);
            const d = format(a.getDate());
            const h = format(a.getHours());
            const mm = format(a.getMinutes());
            const s = format(a.getSeconds());

            return `${y}-${m}-${d} ${h}:${mm}:${s}`;
        },
        getStatus(status) {
            switch (status) {
                case 0:
                    return "审核中";
                case 1:
                    return "生效中";
                case 2:
                    return "不通过";
                case 3:
                    return "已到期";
                default:
                    return "审核中";
            }
        },
        getBtnTxt(status) {
            switch (status) {
                case 1:
                    return "下架";
                case 2:
                    return "修改";
                case 3:
                    return "重新申请";
                default:
                    return "-";
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.seo {
    .title {
        display: flex;
        line-height: 30px;
        align-items: flex-end;

        h4 {
            text-decoration: underline;
            color: red;
            font-size: 14px;
            color: #ff0000;
            line-height: 21px;
            margin-left: 10px;
            cursor: pointer;
        }
    }
    .btn {
        height: 42px;
        width: 119px;
        background: #f00;
        border-radius: 6px;
        color: #fff;
        line-height: 42px;
        text-align: center;
        font-size: 15px;
        margin-top: 14px;
        cursor: pointer;
    }

    .invalid {
        background-color: #ccc;
    }

    .nopass {
        height: 26px;
        width: 66px;
        background: #ffe5e5;
        border-radius: 4px;
        background-color: #ffe5e5;
        text-align: center;
        color: #f00;
    }

    .pending {
        height: 26px;
        width: 66px;
        background: #fff1e5;
        border-radius: 4px;
        background-color: #fff1e5;
        text-align: center;
        color: #ff4900;
    }
    .valid {
        height: 26px;
        width: 66px;
        background: #f0f0f0;
        border-radius: 4px;
        background-color: #f0f0f0;
        text-align: center;
    }

    .outdate {
        height: 26px;
        width: 66px;
        background: #f0f0f0;
        border-radius: 4px;
        background-color: #f0f0f0;
        color: #999;
        text-align: center;
    }
    .valid {
        height: 26px;
        width: 66px;
        background: #f0f0f0;
        border-radius: 4px;
        background-color: #f0f0f0;
        text-align: center;
    }

    .link {
        text-decoration: underline;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        width: 300px;
        cursor: pointer;
        text-align: center;
    }

    .tab {
        margin-top: 17px;
        background-color: #fff;
        border: 1px solid #eee;
        border-radius: 4px;
        padding: 20px;

        .empty {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 480px;

            img {
                height: 93px;
                width: 81.4px;
            }
        }
    }

    .reason {
        font-size: 12px;
        color: #f00;
        font-weight: bold;
    }
    .warning {
        color: #999;
        margin-left: 6px;
        font-size: 14px;
    }

    .tip {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        height: 36px;
        align-items: center;
        width: 375px;
        background: #fffbe6;
        border-radius: 8px;
        background-color: #fffbe6;
        position: absolute;
        top: -45px;
        left: 0px;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #f86e21;
        .tip1 {
            display: flex;
            align-items: center;
            img {
                width: 20px;
                height: 20px;
            }
        }

        .doc {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
    }
}
</style>

<style>
.cell {
    display: flex !important;
    justify-content: center !important;
}

.el-dialog {
    width: 786px !important;
    border-radius: 8px !important;
}
</style>
